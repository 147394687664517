/* eslint-disable max-len */
import React from 'react';

const QRHistoryIcon = ({ color = '#8D97AD' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="26"
      width="100"
      viewBox="10 0 30 50"
      fill="none">
      <path
        d="M23.85 42q-7.45 0-12.65-5.275T6 23.95h3q0 6.25 4.3 10.65T23.85 39q6.35 0 10.75-4.45t4.4-10.8q0-6.2-4.45-10.475Q30.1 9 23.85 9q-3.4 0-6.375 1.55t-5.175 4.1h5.25v3H7.1V7.25h3v5.3q2.6-3.05 6.175-4.8Q19.85 6 23.85 6q3.75 0 7.05 1.4t5.775 3.825q2.475 2.425 3.9 5.675Q42 20.15 42 23.9t-1.425 7.05q-1.425 3.3-3.9 5.75-2.475 2.45-5.775 3.875Q27.6 42 23.85 42Zm6.4-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z"
        fill={color}
      />
    </svg>
  );
};

export default QRHistoryIcon;
