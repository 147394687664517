import classNames from 'classnames';
import React from 'react';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useAppConfig } from '../../hooks';
import { useAccount } from '../../hooks/hooks';
import useResponsive from '../../hooks/useResponsive';
import { HasAuthority } from '../../shared/components';
import { useAuthority } from '../../shared/components/HasAuthority';
import nav from './_nav';

// eslint-disable-next-line react/prop-types
const AppSidebar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const current = useLocation();
  const { accountDetails, token, logout, loading } = useAccount();
  const { isMobile } = useResponsive();
  const { hasAuth } = useAuthority();
  const { hasRvmLocation } = useAppConfig();

  const filteredNav = nav?.filter((item) => {
    if (item?.title === 'RVM' && !hasRvmLocation) return false;
    return true;
  });

  if (!current) return null;
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint={isMobile ? 'xs' : 'md'}
      className="AppSidebar dark"
      onToggle={handleToggleSidebar}>
      <SidebarContent>
        <Menu iconShape="circle">
          {accountDetails &&
            filteredNav.map((menu, idx) =>
              // eslint-disable-next-line no-nested-ternary
              hasAuth(menu.authorities) && menu.children ? (
                <SubMenu
                  key={menu.url}
                  defaultOpen={current.pathname?.includes(menu.url)}
                  className={classNames({
                    'submenu-active': current.pathname?.includes(menu.url),
                  })}
                  title={menu.title}
                  icon={menu.icon}>
                  {menu.children?.map((m) => (
                    <HasAuthority authorities={m?.authorities}>
                      <MenuItem
                        className="child-menu-item"
                        key={m.url}
                        active={current.pathname === m.url}>
                        {m.title}
                        <Link
                          to={m.url}
                          onClick={isMobile ? () => handleToggleSidebar(false) : () => undefined}
                        />
                      </MenuItem>
                    </HasAuthority>
                  ))}
                </SubMenu>
              ) : hasAuth(menu.authorities) ? (
                <MenuItem
                  key={menu.url}
                  icon={menu.icon}
                  active={
                    current.pathname === menu.url ||
                    (menu.url !== '/' && current.pathname?.includes(menu.url))
                  }>
                  {menu.title}
                  <Link
                    to={menu.url}
                    onClick={isMobile ? () => handleToggleSidebar(false) : () => undefined}
                  />
                </MenuItem>
              ) : (
                <div key={idx} />
              )
            )}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          style={{
            padding: '10px 24px',
            color: '#dcdcdc',
          }}>
          <a
            title="Read More"
            href="https://recykal.com/deposit-refund-system/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-light"
            style={{
              color: '#828282',
            }}>
            {/* v{process.env.REACT_APP_VERSION} */}
            DRS
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

AppSidebar.propTypes = {};

export default AppSidebar;
