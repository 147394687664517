import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './assets/scss/style.scss';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/* This code is rendering the React application to the DOM. It is using the `ReactDOM.render()` method
to render the application components wrapped in various providers and routers. The `ToastContainer`
component is used to display toast notifications, while the `GoogleOAuthProvider` component is used
to provide Google OAuth authentication. The `ReduxProvider` component is used to provide access to
the Redux store, and the `BrowserRouter` and `QueryParamProvider` components are used to handle
routing and query parameters. Finally, the `App` component is rendered inside all of these providers
and routers, and the resulting output is inserted into the HTML element with the ID of `root`. */
ReactDOM.render(
  <>
    <ToastContainer autoClose={3000} position="top-center" hideProgressBar closeOnClick />
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </BrowserRouter>
      </ReduxProvider>
    </GoogleOAuthProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
