import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountDetails: null,
  users: [],
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const userActions = UserSlice.actions;
export default UserSlice.reducer;
