/* eslint-disable max-len */
import React from 'react';

const ManageQrIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.78125 0C0.349991 0 0 0.349991 0 0.78125V10.1562C0 10.5875 0.349991 10.9375 0.78125 10.9375H10.1562C10.5875 10.9375 10.9375 10.5875 10.9375 10.1562V0.78125C10.9375 0.349991 10.5875 0 10.1562 0H0.78125ZM14.8438 0C14.4125 0 14.0625 0.349991 14.0625 0.78125V10.1562C14.0625 10.5875 14.4125 10.9375 14.8438 10.9375H24.2188C24.65 10.9375 25 10.5875 25 10.1562V0.78125C25 0.349991 24.65 0 24.2188 0H14.8438ZM1.5625 1.5625H9.375V9.375H1.5625V1.5625ZM15.625 1.5625H23.4375V9.375H15.625V1.5625ZM0.78125 14.0625C0.349991 14.0625 0 14.4125 0 14.8438V24.2188C0 24.65 0.349991 25 0.78125 25H10.1562C10.5875 25 10.9375 24.65 10.9375 24.2188V14.8438C10.9375 14.4125 10.5875 14.0625 10.1562 14.0625H0.78125ZM14.8438 14.0625C14.4125 14.0625 14.0625 14.4125 14.0625 14.8438V18.75H15.625V15.625H18.75V14.0625H14.8438ZM21.0938 14.0625C20.6625 14.0625 20.3125 14.4125 20.3125 14.8438V18.75H21.875V15.625H25V14.0625H21.0938ZM1.5625 15.625H9.375V23.4375H1.5625V15.625ZM17.1875 18.75V20.3125H16.4062C15.975 20.3125 15.625 20.6625 15.625 21.0938V25H17.1875V21.875H18.75V25H20.3125V21.0938C20.3125 20.6625 19.9625 20.3125 19.5312 20.3125H18.75V18.75H17.1875ZM23.4375 18.75V25H25V18.75H23.4375Z"
        fill="#8D97AD"
      />
    </svg>
  );
};

export default ManageQrIcon;
