import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

/**
 * It takes the arguments passed to the query, the WebApi object, and any extra options, and returns
 * the result of the base query with the headers set.
 * Initialize an empty api service that we'll inject endpoints into later as needed.
 * @param args - The arguments that will be passed to the API.
 * @param WebApi - This is the WebApi object that you created in the previous step.
 * @param extraOptions - This is an object that contains the following properties:
 * @returns the rawBaseQuery function.
 */
const dynamicBaseQuery = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('userToken');
      headers.set('Content-Type', 'application/json');
      headers.set('appCode', process.env.REACT_APP_CODE);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

/**
 * It takes in an API call, executes it, and if it fails with a 401, it tries to get a new token or
 * logout. If it fails with a 400, it shows a toast with error message from API.
 * @param args - the arguments to pass to the API
 * @param api - the api endpoint to call
 * @param extraOptions - {
 * @returns The result of the dynamicBaseQuery function.
 */
const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const result = await dynamicBaseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    // try to get a new token or logout
  } else if (result?.error && result?.error?.status === 400) {
    toast.error(result?.error?.data?.title || 'Something went wrong.');
  }
  return result;
};

export const backendApi = createApi({
  reducerPath: 'backend',
  overrideExisting: true,
  // extractRehydrationInfo(action, { reducerPath }) {
  //   if (action.type === REHYDRATE) {
  //     return action.payload[reducerPath];
  //   }
  // },
  baseQuery: baseQueryWithReAuth,
  // refetchOnMountOrArgChange: 30,
  tagTypes: ['projects', 'account', 'customer', 'trips', 'tenant'],
  endpoints: () => ({}),
});
