import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAppConfig } from '../../../hooks';
import './GooglePlaceAutocomplete.scss';

const GooglePlaceAutocomplete = (props) => {
  const { value } = { ...props };
  const [inputValue, setInputValue] = useState(value);
  const { states } = useAppConfig();
  const ref = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const addressObj = {
    street: '',
    zipCode: '',
    latLng: { lat: '', lng: '' },
    city: '',
    state: '',
  };

  const parseResult = (result) => {
    const isEqual = (a, b) => a?.trim()?.toLocaleString() === b?.trim()?.toLocaleString();
    const addrComponent = result.address_components;
    const cityName = addrComponent.find((comp) => comp.types.indexOf('locality') >= 0)?.long_name;
    const zipCode = addrComponent.find((comp) => comp.types.indexOf('postal_code') >= 0)?.long_name;
    const stateName = addrComponent.find(
      (comp) => comp.types.indexOf('administrative_area_level_1') >= 0
    )?.long_name;
    addressObj.state = stateName;
    addressObj.street = result.formatted_address;
    addressObj.city = cityName;
    addressObj.zipCode = zipCode;
  };

  const handleSelect = (address, zipCode, city, sourceType, sourceName) => {
    setInputValue(address);
    geocodeByAddress(address)
      .then((results) => {
        parseResult(results[0]);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        addressObj.latLng = latLng;
        addressObj.street = address;
        addressObj.sourceType = sourceType;
        // addressObj.city = city || addressObj.city;
        addressObj.sourceName = sourceName;
        if (addressObj.zipCode === undefined) {
          addressObj.zipCode = zipCode;
        }
      })
      .finally((e) => {
        props.handleSelect(addressObj);
      })
      .catch((error) => {
        if (error) {
          // handle error
        }
      });
  };

  const handleChange = (input) => {
    setInputValue(input);
    props.handleChange(input);
  };

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        types: ['establishment'],
        componentRestrictions: { country: 'IN' },
      }}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <InputGroup ref={ref}>
          <FormControl
            {...getInputProps({
              placeholder: props.placeholder || 'Search Places ...',
              className: props.className,
            })}
            isInvalid={props.isInvalid}
          />
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon1" style={{ backgroundColor: 'white' }}>
              <FaMapMarkerAlt />
            </InputGroup.Text>
          </InputGroup.Append>
          <div className="w-100 GooglePlaceAutocomplete p-0 autocomplete-dropdown-container">
            {suggestions.map((suggestion) => {
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#e3edff', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: 'result-row border-bottom',
                    style,
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </InputGroup>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlaceAutocomplete;
