/* eslint-disable max-len */
import React from 'react';

const DashboardIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.82165 1.26514H2.69465C2.31547 1.2654 1.9519 1.41615 1.68378 1.68427C1.41566 1.95239 1.26491 2.31596 1.26465 2.69514V6.37114C1.26491 6.75031 1.41566 7.11389 1.68378 7.38201C1.9519 7.65013 2.31547 7.80087 2.69465 7.80114H8.82165C9.20083 7.80087 9.5644 7.65013 9.83252 7.38201C10.1006 7.11389 10.2514 6.75031 10.2516 6.37114V2.69514C10.2514 2.31596 10.1006 1.95239 9.83252 1.68427C9.5644 1.41615 9.20083 1.2654 8.82165 1.26514V1.26514Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.82165 10.2982H2.69465C2.31547 10.2984 1.9519 10.4492 1.68378 10.7173C1.41566 10.9854 1.26491 11.349 1.26465 11.7282L1.26465 20.3062C1.26491 20.6853 1.41566 21.0489 1.68378 21.317C1.9519 21.5851 2.31547 21.7359 2.69465 21.7362H8.82165C9.20083 21.7359 9.5644 21.5851 9.83252 21.317C10.1006 21.0489 10.2514 20.6853 10.2516 20.3062V11.7282C10.2514 11.349 10.1006 10.9854 9.83252 10.7173C9.5644 10.4492 9.20083 10.2984 8.82165 10.2982V10.2982Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.306 15.2001H14.179C13.7998 15.2004 13.4363 15.3511 13.1682 15.6193C12.9 15.8874 12.7493 16.251 12.749 16.6301V20.3061C12.7493 20.6853 12.9 21.0489 13.1682 21.317C13.4363 21.5851 13.7998 21.7359 14.179 21.7361H20.306C20.6852 21.7359 21.0488 21.5851 21.3169 21.317C21.585 21.0489 21.7358 20.6853 21.736 20.3061V16.6301C21.7358 16.251 21.585 15.8874 21.3169 15.6193C21.0488 15.3511 20.6852 15.2004 20.306 15.2001V15.2001Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.306 1.26514H14.179C13.7998 1.2654 13.4363 1.41615 13.1682 1.68427C12.9 1.95239 12.7493 2.31596 12.749 2.69514V11.2731C12.7493 11.6523 12.9 12.0159 13.1682 12.284C13.4363 12.5521 13.7998 12.7029 14.179 12.7031H20.306C20.6852 12.7029 21.0488 12.5521 21.3169 12.284C21.585 12.0159 21.7358 11.6523 21.736 11.2731V2.69514C21.7358 2.31596 21.585 1.95239 21.3169 1.68427C21.0488 1.41615 20.6852 1.2654 20.306 1.26514V1.26514Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
