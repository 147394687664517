import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
};

const LocationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const locationActions = LocationSlice.actions;

export default LocationSlice.reducer;
