import './UserChangePassword.scss';

import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Image,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import HideEyeIcon from '../../assets/img/hide_eye_icon.png';
import ShowEyeIcon from '../../assets/img/show_eye_icon.png';
import { useAccountPassword } from '../../hooks/hooks';
import { hasError } from '../../shared/utils/Helper';

const UserChangePassword = (props) => {
  const { show, setShow, account } = { ...props };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [{ value }, { doGetAccount, doUpdatePassword }] = useAccountPassword();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: () => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          oldPassword: Yup.string().required('Old Password is required'),
          newPassword: Yup.string().required('New Password is required'),
          confirmPassword: Yup.string().required('Confirm Password is required'),
        });
      });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const payload = {
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const res = await doUpdatePassword(payload);
      const error = hasError(res);
      if (error) {
        toast.error(error?.title);
      } else {
        await doGetAccount();
        toast.success('Updated password successfully');
      }
      closeModal();
    },
  });

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      className="UserChangePassword"
      onExit={() => formik.resetForm()}
      size="md"
      backdrop="static"
      centered>
      <Modal.Header className="text-large" closeButton onHide={closeModal}>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Current Password</Form.Label>
              <InputGroup>
                <FormControl
                  type={showOldPassword ? 'text' : 'password'}
                  className="input-form"
                  placeholder="Enter Current password"
                  {...formik.getFieldProps('oldPassword')}
                  isInvalid={formik.touched.oldPassword && !!formik.errors.oldPassword}
                />
                <InputGroup.Append className="input-eye-icon">
                  <Image
                    src={showOldPassword ? ShowEyeIcon : HideEyeIcon}
                    className="mr-2 mt-2 pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    style={{ width: '15px', height: '15px' }}
                  />
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.oldPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Enter New Password</Form.Label>
              <InputGroup>
                <FormControl
                  type={showNewPassword ? 'text' : 'password'}
                  className="input-form"
                  placeholder="Enter new password"
                  {...formik.getFieldProps('newPassword')}
                  isInvalid={formik.touched.newPassword && !!formik.errors.newPassword}
                />
                <InputGroup.Append className="input-eye-icon">
                  <Image
                    src={showNewPassword ? ShowEyeIcon : HideEyeIcon}
                    className="mr-2 mt-2 pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    style={{ width: '15px', height: '15px' }}
                  />
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Re-Enter New Password</Form.Label>
              <InputGroup>
                <FormControl
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Re-enter new password"
                  className="input-form"
                  {...formik.getFieldProps('confirmPassword')}
                  isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                />
                <InputGroup.Append className="input-eye-icon">
                  <Image
                    src={showConfirmPassword ? ShowEyeIcon : HideEyeIcon}
                    className="mr-2 mt-2 pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{ width: '15px', height: '15px' }}
                  />
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {formik.values.newPassword &&
              formik.values.confirmPassword &&
              formik.values.newPassword !== formik.values.confirmPassword && (
                <FormGroup as={Col} md="12">
                  <div className="text-small text-danger">
                    Password and Confirm password didn&#39;t match
                  </div>
                </FormGroup>
              )}
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="pr-5 pl-5 mr-3 cancel-button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={formik.values.newPassword !== formik.values.confirmPassword}
          onClick={formik.handleSubmit}
          style={{ padding: '5px 50px 5px 50px' }}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UserChangePassword;
