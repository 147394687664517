import React from 'react';

const CustomersIcon = ({ color = '', size = '25' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 36 30.13">
      <g id="Group_8288" data-name="Group 8288" transform="translate(3156.5 1584.63)">
        <g
          id="Icon_feather-users"
          data-name="Icon feather-users"
          transform="translate(-3156.5 -1587.5)">
          <path
            id="Path_8930"
            data-name="Path 8930"
            d="M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_8931"
            data-name="Path 8931"
            d="M19.5,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_8932"
            data-name="Path 8932"
            d="M34.5,31.5v-3A6,6,0,0,0,30,22.7"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_8933"
            data-name="Path 8933"
            d="M24,4.7A6,6,0,0,1,24,16.32"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  );
};

export default CustomersIcon;
