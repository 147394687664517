import React, { Suspense, useState } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useAppConfig } from '../../hooks';
import { useInitApp, useValidateToken } from '../../hooks/hooks';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import routes from '../../routes';
import { Loader } from '../../shared/components';
import { useAuthority } from '../../shared/components/HasAuthority';

const MainContent = (props) => {
  const [{ loading }, { loadPostLoginConfig }] = useInitApp();
  const [appLoaded, setAppLoaded] = useState(false);
  const { hasRvmLocation } = useAppConfig();
  const { hasAuth } = useAuthority();
  const { loading: tokenLoading } = useValidateToken();

  useAsyncEffect(async () => {
    setAppLoaded(true);
    if (hasAuth(['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'])) {
      await loadPostLoginConfig();
    }
  }, []);

  const filteredRoutes = routes?.filter((item) => {
    if (item?.name === 'RVM Page' && !hasRvmLocation) return false;
    return true;
  });

  return (
    <main className="AppContent">
      <Suspense fallback={<Loader />}>
        {!loading && appLoaded && !tokenLoading ? (
          <Switch>
            {/* {hasAuth(['ROLE_PLATFORM_ADMIN']) &&
             <Redirect path="/" to="/tenants" exact />} */}
            {filteredRoutes.map(
              (route, idx) =>
                hasAuth(route?.authorities, route?.noAuthorities) && (
                  <Route
                    key={`route-${idx}`}
                    name={route.name}
                    exact={route.exact}
                    path={route.path}
                    render={(routeProps) => (
                      <>
                        <Helmet>
                          <title>{route.name} | DRS</title>
                        </Helmet>
                        <route.component {...routeProps} />
                      </>
                    )}
                  />
                )
            )}
            <Redirect path="/login" to="/" />
            <Route path="*" name="Not Found" render={() => <h2>Page Not Found</h2>} />
          </Switch>
        ) : (
          <Loader />
        )}
      </Suspense>
    </main>
  );
};

MainContent.propTypes = {};

export default MainContent;
