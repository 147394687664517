import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './PromptModal.scss';
import classNames from 'classnames';

const PromptModal = (props) => {
  const {
    show,
    onHide,
    title,
    text,
    hideDismissBtn,
    hideSuccessBtn,
    dismissBtnText,
    successBtnText,
    dismissBtnClass,
    successBtnClass,
    onDismiss,
    onSuccess,
    variant,
  } = props;

  return (
    <Modal show={show} onHide={onDismiss} className="modal-small PromptModal" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title || ''}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>

      <Modal.Footer>
        {!hideDismissBtn && (
          <Button
            className={classNames('btn-wide', dismissBtnClass)}
            variant="secondary"
            onClick={onDismiss}>
            {dismissBtnText || 'No'}
          </Button>
        )}
        {!hideSuccessBtn && (
          <Button
            className={classNames('btn-wide', successBtnClass)}
            variant={variant || 'primary'}
            onClick={onSuccess}>
            {successBtnText || 'Yes'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default PromptModal;
