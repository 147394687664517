const FilterParamMap = {
  USERS: {
    id: 'id.equals',
    firstName: 'name.contains',
    mobile: 'mobile.contains',
    email: 'email.equals',
    activated: 'active.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
    role: 'role.equals',
    subTenantId: 'subTenantId.equals',
  },

  CUSTOMERS: {
    formattedId: 'formattedId.contains',
    name: 'name.contains',
    shopName: 'shopName.contains',
    mobile: 'mobile.contains',
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
    activated: 'activated.equals',
  },

  LOCATIONS: {
    type: 'type.in',
    page: 'page',
    size: 'size',
    sort: 'sort',
    typeEquals: 'type.equals',
    activated: 'activated.equals',
    name: 'name.contains',
    subTenantId: 'subTenantId.equals',
  },

  QR_CODES: {
    id: 'id.equals',
    status: 'status.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
    idIn: 'id.in',
  },

  TRANSACTIONS: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
    locationId: 'locationId.equals',
    type: 'type.equals',
    status: 'status.equals',
    fromTimestamp: 'fromTimestamp.equals',
    toTimestamp: 'toTimestamp.equals',
    paymentMode: 'paymentMode.equals',
    subTenantId: 'subTenantId.equals',
  },

  REPORTS: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
    locationId: 'locationId',
    status: 'status',
    fromDate: 'fromDate',
    toDate: 'toDate',
    subTenantId: 'subTenantId',
  },
  SUMMARY: {
    qSearch: 'qSearch.contains',
    qrId: 'qrId.equals',
    vpa: 'vpa.contains',
    sort: 'sort',
    size: 'size',
  },
};

export default FilterParamMap;
