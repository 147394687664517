import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { backendApi } from './apis/base.config';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, backendApi.middleware),
});

export default store;
