import { isEmpty, keyBy } from 'lodash';
import { useSelector } from 'react-redux';

/**
 * This function returns an object containing information about stakeholders, including the current
 * account, account users, users, and whether there are sub-tenants.
 * @returns The `useStakeholders` function returns an object with the following properties:
 */
export default function useStakeholders() {
  const {
    currentAccount,
    isCurrentUser,
    accountUsers,
    users,
    hasSubTenants,
    subTenantsList,
    subTenantsMap,
  } = useSelector(({ user, accountUser }) => {
    const _currentAccount = user?.accountDetails;
    const _hasSubTenants = !isEmpty(_currentAccount?.mappedSubTenants);
    const _accountUsers = keyBy(accountUser?.accountUsers, 'userId');
    const _users = keyBy(user?.users, 'userId');
    const _isCurrentUser = (id) => id === currentAccount?.id;
    const _subTenantsList = _currentAccount?.mappedSubTenants || [];
    const _subTenantsMap = keyBy(_subTenantsList, 'id');
    return {
      currentAccount: _currentAccount,
      accountUsers: _accountUsers,
      users: _users,
      hasSubTenants: _hasSubTenants,
      isCurrentUser: _isCurrentUser,
      subTenantsList: _subTenantsList,
      subTenantsMap: _subTenantsMap,
    };
  });
  return {
    currentAccount,
    isCurrentUser,
    accountUsers,
    users,
    hasSubTenants,
    subTenantsList,
    subTenantsMap,
  };
}
