import './Buttons.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

/**
 * It renders a button with a spinner if the loading prop is true
 * @returns A button with a spinner and the children passed in.
 */
const ButtonLoading = ({ children, loading = false, className, disabled = false, ...rest }) => {
  return (
    <Button className={`ButtonLoading ${className}`} disabled={disabled || loading} {...rest}>
      {loading && <Spinner size="sm" animation="border" />}
      {children}
    </Button>
  );
};

ButtonLoading.propTypes = {
  loading: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default ButtonLoading;
