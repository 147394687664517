import axios from 'axios';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';

import AppAnalytics from '../services/AppAnalytics';
import {
  changePassword,
  createPassword,
  createTenant,
  doAssignQrCodes,
  doCreateCustomers,
  doCreateLocation,
  doCreateUnScannedTxn,
  doCreateUser,
  doDownloadCustomers,
  doFetchCustomers,
  doFetchLots,
  doFetchTransactions,
  doPrintQrCodes,
  doUpdateCustomers,
  doUpdateLocation,
  doUpdateUser,
  fetchAccount,
  fetchDashboardStats,
  fetchLocations,
  fetchQrCodeStats,
  fetchUsers,
  getCollectionTxnReport,
  getDistributionTxnReport,
  getOfflineTxnReport,
  getReturnUnusedTxnReport,
  getShopTxnReport,
  getTenants,
  initPostAuthApis,
  login,
  googleLogin,
  resetPassword,
  updateAccount,
  validateGoogelToken,
  doPrintBulkQrCodes,
} from '../services/backend-api';
import { ErrorCode } from '../shared/constants';
import { authActions } from '../store/Auth/auth.reducer';
import { configActions } from '../store/Config/config.reducer';
import { locationActions } from '../store/Location/location.reducer';
import { userActions } from '../store/User/user.reducer';
import useAsyncEffect from './useAsyncEffect';

/** =====================================================
 * ===================== AUTH HOOK ===================
 * ===================================================== */
export function useLogin() {
  const dispatch = useDispatch();
  const [{ error, loading, value }, doLogin] = useAsyncFn(async (payload) => {
    const response = await login(payload);
    dispatch(authActions.setToken(response.id_token));
    return response;
  }, []);
  return [{ error, loading, value }, doLogin];
}

export function useGoogleSignin() {
  const dispatch = useDispatch();
  const [{ error, loading, value }, doGoogleLogin] = useAsyncFn(async (payload) => {
    const response = await googleLogin(payload);
    dispatch(authActions.setToken(response.id_token));
    dispatch(authActions.setRefreshToken(response.refresh_token));
    return response;
  }, []);
  return [{ error, loading, value }, doGoogleLogin];
}

export function useRequestPassword() {
  const [restPassState, doResetPassword] = useAsyncFn(async (payload) => {
    const response = await resetPassword(payload);
    return response;
  }, []);

  const [createPassState, doCreatePassword] = useAsyncFn(async (payload) => {
    const response = await createPassword(payload);
    return response;
  }, []);

  return [
    {
      error: restPassState.error || createPassState.error,
      loading: restPassState.loading || createPassState.loading,
      value: restPassState.value || createPassState.value,
    },
    { doResetPassword, doCreatePassword },
  ];
}

/** =====================================================
 * =================== ACCOUNT HOOK =================
 * ===================================================== */
export function useValidateToken() {
  // eslint-disable-next-line no-shadow
  const { refreshToken } = useSelector(({ auth: { refreshToken } }) => {
    return {
      refreshToken: refreshToken || localStorage.getItem('refreshToken'),
    };
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useAsyncEffect(async () => {
    if (refreshToken) {
      setLoading(true);
      try {
        await validateGoogelToken({ refreshToken });
        setLoading(false);
        dispatch(authActions.setRefreshToken(refreshToken));
      } catch (error) {
        if (error?.status === 401) {
          axios.defaults.headers.common.Authorization = ``;
          dispatch(authActions.setToken(null));
          dispatch(authActions.setRefreshToken(null));
          window.location.reload();
        }
      }
    }
  }, [refreshToken]);

  return { refreshToken, loading };
}

export function useAccount(force) {
  // eslint-disable-next-line no-shadow
  const { accountDetails, token } = useSelector(({ user: { accountDetails }, auth: { token } }) => {
    return {
      accountDetails,
      token: token || localStorage.getItem('userToken'),
    };
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useAsyncEffect(async () => {
    if (!token) return;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (force) {
      setLoading(true);
      try {
        const account = token ? await fetchAccount() : null;
        setLoading(false);
        dispatch(userActions.setUserDetails(account));
        account && AppAnalytics.identity(account);
      } catch (error) {
        if (error?.errorKey === ErrorCode.AUTHORIZATION_FAILURE) {
          axios.defaults.headers.common.Authorization = ``;
          logout();
        }
      }
    }
  }, [token]);

  function logout() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      googleRevoke(refreshToken);
    }
    dispatch(authActions.setToken(null));
    dispatch(authActions.setRefreshToken(null));
  }

  return { accountDetails, logout, token, loading };
}

async function googleRevoke(refreshToken) {
  if (refreshToken) {
    const url = `https://oauth2.googleapis.com/revoke?token=${refreshToken}`;
    const options = {
      method: 'POST',
    };
    await fetch(url, options);
  }
}

/** =====================================================
 * =================== ACCOUNT PASSWORD =================
 * ===================================================== */
export function useAccountPassword() {
  const [updateState, doUpdateAccount] = useAsyncFn(async (payload) => {
    const response = await updateAccount(payload);
    return response;
  });

  const [getState, doGetAccount] = useAsyncFn(async () => {
    const res = await fetchAccount();
    return res;
  });

  const [updatePassState, doUpdatePassword] = useAsyncFn(async (payload) => {
    const res = await changePassword(payload);
    return res;
  });

  return [
    {
      error: updatePassState.error || getState.error || getState.error,
      loading: updatePassState.loading || getState.loading || getState.loading,
      value: updatePassState.value || getState.value || getState.value,
    },
    { doUpdateAccount, doGetAccount, doUpdatePassword },
  ];
}

/** =====================================================
 * ================= LOAD INIT APP DATA =================
 * ===================================================== */
export function useInitApp() {
  const dispatch = useDispatch();
  const [postState, loadPostLoginConfig] = useAsyncFn(async (payload) => {
    const response = await initPostAuthApis();
    if (!isEmpty(response)) {
      dispatch(locationActions.setLocations(response[0]?.data));
      dispatch(userActions.setUsers(response[1]?.data));
      dispatch(configActions.setContainerType(response[2]?.data));
    }
    return response;
  }, []);
  return [
    {
      error: postState.error,
      loading: postState.loading,
      value: postState.value,
    },
    { loadPostLoginConfig },
  ];
}

/** =====================================================
 * =================== LOAD USER DATA =================
 * ===================================================== */
export function useUsers() {
  const [fetchState, fetchUser] = useAsyncFn(async (params) => {
    const response = await fetchUsers(params);
    return response;
  });

  const [createState, createUser] = useAsyncFn(async (payload) => {
    const response = await doCreateUser(payload);
    // fetchUser();
    return response;
  });

  const [updateState, updateUser] = useAsyncFn(async (payload) => {
    const response = await doUpdateUser(payload);
    // fetchUser();
    return response;
  });

  return [
    {
      error: fetchState.error || createState.error || updateState.error,
      loading: fetchState.loading || createState.loading || updateState.loading,
      value: fetchState.value || createState.value || updateState.value,
    },
    { fetchUser, createUser, updateUser },
  ];
}

/** =====================================================
 * =================== LOAD CUSTOMER DATA =================
 * ===================================================== */
export function useCustomers() {
  const [fetchState, fetchCustomer] = useAsyncFn(async (params) => {
    const response = await doFetchCustomers(params);
    return response;
  });

  const [createState, createCustomers] = useAsyncFn(async (payload) => {
    const response = await doCreateCustomers(payload);
    return response;
  });

  const [updateState, updateCustomers] = useAsyncFn(async (payload) => {
    const response = await doUpdateCustomers(payload);
    return response;
  });

  const [downloadState, downloadCustomers] = useAsyncFn(async (payload) => {
    const response = await doDownloadCustomers(payload);
    const data = response?.data;
    return data;
  }, []);

  return [
    {
      error: fetchState.error || createState.error || updateState.error,
      loading: fetchState.loading || createState.loading || updateState.loading,
      value: fetchState.value || createState.value || updateState.value,
    },
    { fetchCustomer, createCustomers, updateCustomers, downloadCustomers },
  ];
}

/** =====================================================
 * =================== LOAD LOCATIONS DATA =================
 * ===================================================== */
export function useLocations() {
  const dispatch = useDispatch();
  const [fetchState, fetchLocation] = useAsyncFn(async (params) => {
    const response = await fetchLocations(params);
    return response;
  });

  const [refetchState, reFetchLocation] = useAsyncFn(async () => {
    const response = await fetchLocations({ size: 1000 });
    dispatch(locationActions.setLocations(response.data));
    return response;
  });

  const [createState, createLocation] = useAsyncFn(async (payload) => {
    const response = await doCreateLocation(payload);
    // fetchLocation();
    return response;
  });

  const [updateState, updateLocation] = useAsyncFn(async (payload) => {
    const response = await doUpdateLocation(payload);
    // fetchLocation();
    return response;
  });

  return [
    {
      error: fetchState.error || createState.error || updateState.error,
      loading: fetchState.loading || createState.loading || updateState.loading,
      value: fetchState.value || createState.value || updateState.value,
    },
    { fetchLocation, createLocation, updateLocation, reFetchLocation },
  ];
}

/** =====================================================
 * =================== MANAGE QR CODES =================
 * ===================================================== */
export function useManageQrCodes() {
  const [
    { error: printQrCodeError, loading: printQrCodeLoading, value: printQrCodeList },
    printQrCode,
  ] = useAsyncFn(doPrintQrCodes, []);

  const [
    { error: printBulkQrCodeError, loading: printBulkQrCodeLoading, value: printBulkQrCodeList },
    printBulkQrCode,
  ] = useAsyncFn(doPrintBulkQrCodes, []);

  const [
    { error: assignQrCodeError, loading: assignQrCodeLoading, value: assignQrCodeList },
    assignQrCode,
  ] = useAsyncFn(async (payload) => {
    const response = await doAssignQrCodes(payload);
    const data = response?.data;
    return data;
  }, []);

  const [lotsState, fetchLots] = useAsyncFn(async (payload) => {
    const response = await doFetchLots(payload);
    return response;
  }, []);

  return [
    {
      printBulkQrCodeError,
      printBulkQrCodeLoading,
      printBulkQrCodeList,
      printQrCodeError,
      printQrCodeLoading,
      printQrCodeList,
      assignQrCodeError,
      assignQrCodeLoading,
      assignQrCodeList,
      lotsState,
    },
    { printQrCode, assignQrCode, fetchLots, printBulkQrCode },
  ];
}

/** =====================================================
 * =================== TRANSACTIONS =================
 * ===================================================== */
export function useTransactions() {
  const [transactionsState, fetchTransactions] = useAsyncFn(async (payload) => {
    const response = await doFetchTransactions(payload);
    return response;
  }, []);

  const [createState, createUnScannedTxn] = useAsyncFn(async (payload) => {
    const response = await doCreateUnScannedTxn(payload);
    return response;
  }, []);

  return [
    {
      transactionsState,
      createState,
    },
    { fetchTransactions, createUnScannedTxn },
  ];
}

/** =====================================================
 * =================== LOAD DASHBOARD DATA =================
 * ===================================================== */
export function useDashboardData() {
  const [
    { error: dashboardStatsError, loading: dashboardStatsLoading, value: dashboardStatsData },
    doFetchDashboardStats,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchDashboardStats(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: qrCodeStatsError, loading: qrCodeStatsLoading, value: qrCodeStatsData },
    doFetchQrCodeStats,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchQrCodeStats(payload);
    const data = response?.data;
    return data;
  }, []);

  return [
    {
      dashboardStatsError,
      dashboardStatsLoading,
      dashboardStatsData,
      qrCodeStatsError,
      qrCodeStatsLoading,
      qrCodeStatsData,
    },
    { doFetchDashboardStats, doFetchQrCodeStats },
  ];
}

/** =====================================================
 * =================== LOAD REPORTS DATA =================
 * ===================================================== */
export function useReports() {
  const [shopTxnState, doGetShopTxnReport] = useAsyncFn(async (params) => {
    const response = await getShopTxnReport(params);
    return response.data;
  }, []);

  const [distributionTxnState, doGetDistributionTxnReport] = useAsyncFn(async (params) => {
    const response = await getDistributionTxnReport(params);
    return response.data;
  }, []);

  const [collectionTxnState, doCollectionTxnReport] = useAsyncFn(async (params) => {
    const response = await getCollectionTxnReport(params);
    return response.data;
  }, []);

  const [returnUnusedTxnState, doReturnUnusedTxnReport] = useAsyncFn(async (params) => {
    const response = await getReturnUnusedTxnReport(params);
    return response.data;
  }, []);

  const [offlineTxnState, doOfflineTxnReport] = useAsyncFn(async (params) => {
    const response = await getOfflineTxnReport(params);
    return response.data;
  }, []);

  return [
    {
      shopTxnLoading: shopTxnState.loading,
      shopTxnValue: shopTxnState.value,
      shopTxnError: shopTxnState.error,
      distributionLoading: distributionTxnState.loading,
      distributionValue: distributionTxnState.value,
      distributionError: distributionTxnState.error,
      collectionLoading: collectionTxnState.loading,
      collectionValue: collectionTxnState.value,
      collectionError: collectionTxnState.error,
      returnUnusedLoading: returnUnusedTxnState.loading,
      returnUnusedValue: returnUnusedTxnState.value,
      returnUnusedError: returnUnusedTxnState.error,
      offlineTxnLoading: offlineTxnState.loading,
      offlineTxnValue: offlineTxnState.value,
      offlineTxnError: offlineTxnState.error,
    },
    {
      doGetShopTxnReport,
      doGetDistributionTxnReport,
      doCollectionTxnReport,
      doReturnUnusedTxnReport,
      doOfflineTxnReport,
    },
  ];
}

export function useTenants() {
  const [getTenantsState, doGetTenants] = useAsyncFn(async (params) => {
    const response = await getTenants(params);
    return response?.data;
  }, []);

  const [createTenantState, doCreateTenant] = useAsyncFn(async (payload) => {
    const response = await createTenant(payload);
    return response;
  }, []);
  return [
    {
      loadingTenants: getTenantsState?.loading,
      tenants: getTenantsState?.value,
      errorTenant: createTenantState?.error,
    },
    { doGetTenants, doCreateTenant },
  ];
}
