import { combineReducers } from '@reduxjs/toolkit';
import AccountUserReducer from './AccountsUser/accountUser.reducer';
import { backendApi } from './apis/base.config';
import AuthReducer from './Auth/auth.reducer';
import locationReducer from './Location/location.reducer';
import UserReducer from './User/user.reducer';
import ConfigReducer from './Config/config.reducer';

const appReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  accountUser: AccountUserReducer,
  location: locationReducer,
  config: ConfigReducer,
  [backendApi.reducerPath]: backendApi.reducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
