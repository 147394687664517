/* eslint-disable max-len */
import React from 'react';

const UsersIcon = () => {
  return (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2628 11.24C15.5908 11.24 17.4778 8.948 17.4778 6.12C17.4778 3.292 16.8578 1 13.2628 1C9.66785 1 9.04785 3.292 9.04785 6.12C9.04785 8.948 10.9348 11.24 13.2628 11.24Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2157 19.109C21.1377 14.6 20.4947 13.316 15.5717 12.502C14.9162 13.0252 14.1024 13.3101 13.2637 13.3101C12.425 13.3101 11.6112 13.0252 10.9557 12.502C6.0867 13.302 5.4047 14.572 5.3147 18.962C5.3077 19.32 5.3147 19.813 5.3147 19.771C5.3147 19.849 5.30371 19.52 5.30371 19.771C5.30371 19.771 6.47571 21.934 13.2647 21.934C20.0537 21.934 21.2257 19.771 21.2257 19.771C21.2257 19.609 21.2257 19.497 21.2257 19.421C21.2161 19.3173 21.2128 19.2131 21.2157 19.109Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0205 4.04312C18.1722 3.79224 19.3735 3.91006 20.4545 4.38003C21.8085 5.17203 22.0165 6.47095 21.7335 7.96695C21.4505 9.46295 20.6335 10.602 19.1765 10.642C18.2663 10.6544 17.3629 10.4844 16.5195 10.142"
        strokeWidth="1.6"
      />
      <path
        d="M9.54174 4.04312C8.39007 3.79224 7.18858 3.91006 6.10766 4.38003C4.75366 5.17203 4.54573 6.47095 4.82873 7.96695C5.11173 9.46295 5.92873 10.602 7.38573 10.642C8.2959 10.6544 9.19931 10.4844 10.0427 10.142"
        strokeWidth="1.6"
      />
      <path
        d="M17.3977 12.9093C17.3977 12.9093 20.3017 13.0044 20.6766 12.9096C21.0512 12.7773 21.4127 12.6104 21.7565 12.4111C21.7565 12.4111 24.2829 12.9075 24.6938 14.791C24.9262 16.1868 24.9327 17.6109 24.713 19.0087C23.6507 19.5918 22.4505 19.8767 21.2394 19.8333"
        strokeWidth="1.6"
      />
      <path
        d="M9.16856 12.9086C9.16856 12.9086 6.26454 13.0036 5.8897 12.9089C5.51504 12.7766 5.15351 12.6097 4.80981 12.4103C4.80981 12.4103 2.28529 12.9065 1.87542 14.7899C1.64178 16.1866 1.63444 17.6119 1.85366 19.011C2.91597 19.5941 4.1162 19.879 5.32723 19.8356"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default UsersIcon;
