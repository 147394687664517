import { identity, isArray, isEmpty, keyBy, map, omit, pickBy, toLower } from 'lodash';
import * as moment from 'moment';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import * as paisa from './paisa';

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function debounce(func, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

/**
 * convert filters to server required filter format
 * @param params
 * @param filterMap
 */
export function transformParams(params, filterMap) {
  const newParams = {};
  map(params, (value, k) => {
    if (isArray(filterMap[k]) && isArray(value)) {
      value.forEach((paramValue, index) => (newParams[filterMap[k][index]] = paramValue));
      return true;
    }
    newParams[filterMap[k]] = value;
    // replacing sort param
    if (k === 'sort' && value) {
      const arrValue = value.split(',');
      const newKey = filterMap?.sortParams && filterMap?.sortParams[arrValue[0]];
      if (newKey) {
        arrValue[0] = newKey || arrValue[0];
        newParams.sort = arrValue.join(',');
      }
    }
    return true;
  });
  return pickBy(newParams, identity);
}

/**
 * convert array of id,value to object {key:value}
 * @param filterArray
 * @return {*}
 */
export function parseTableFilters(filterArray) {
  return filterArray?.reduce((obj = {}, f) => {
    obj[f.id] = f.value;
    return obj;
  }, {});
}

/**
 * convert url params to tableFilters
 * @param filterObject
 * @return {unknown[]}
 */
export function convertToTableFilters(filterObject) {
  const _obj = omit(filterObject, ['page', 'size', 'sort', 'refresh']);
  return map(_obj, (v, k) => ({
    id: k,
    value: v,
  }));
}

export function buildUrl(string = '', obj) {
  let s = string;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const prop in obj) {
    s = s.replace(new RegExp(`{${prop}}`, 'g'), obj[prop]);
  }
  return s;
}

function numberWithCommas(x, decimal = 0) {
  if (!x) return x;
  if (decimal) {
    const [val, dec] = x.toString().split('.');
    const postDecimal = dec ? `${dec}`.substring(0, 2) : undefined;
    return [val.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','), postDecimal]
      ?.filter(Boolean)
      ?.join('.');
  }
  return Math.round(x)
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * convert server date to local date format
 * @param dateString
 * @param format
 * @return {string}
 */
const toLocalDate = (dateString, format = 'DD-MMM-YYYY') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

const getDateTimeAge = (dateString) => {
  return moment(dateString).fromNow();
};

const getDateDiff = (dateString = '', units) => {
  const data = moment(dateString);
  return moment().diff(data, units || 'days');
};

const isExist = (list = [], matchKey, matchValue) => {
  return list?.findIndex((i) => i[matchKey] === matchValue) > -1;
};
/**
 * convert local date to Server required format
 * @param date
 * @param onlyDate
 * @param format
 * @return {string}
 */
const toServerDate = (date, onlyDate, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') => {
  const _date = moment(date);
  return onlyDate ? _date.startOf('day').utc().format(format) : _date.utc().format(format);
};

const toLocalDateTime = (dateString, format = 'DD MMM, YYYY [at] h:mm:ss a') => {
  return moment(dateString).format(format);
};

function toCapitalize(str) {
  if (!str) return str;
  let i;
  const frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)?.toLowerCase();
  }
  return frags.join(' ');
}

// TODO make it generic
function deepKeyBy(arr, key = 'id') {
  return keyBy(
    map(arr, (o) => ({
      ...o,
      districts: keyBy(o.districts, key),
    })),
    key
  );
}

function extractError(res) {
  if (res?.data?.title && res?.data?.errorCode && res?.data?.errorType) {
    return res?.data;
  }
  if (!isEmpty(res?.headers) && res?.headers['x-drsApp-error']) {
    return {
      message: res.headers['x-drsApp-error'],
      key: res.headers['x-drsApp-error-key'],
    };
  }
  if (res?.data?.detail) {
    return {
      message: res?.data?.detail,
    };
  }
  if (res?.data?.message) {
    return res?.data;
  }
  return {
    message: 'Error while processing your request.',
  };
}

function hasError(response) {
  if (response?.status >= 400) {
    const error = extractError(response);
    return error;
  }
  return false;
}

const parseStringAddress = async (address, cities) => {
  const addr = address.split(',').filter(Boolean);
  const addressObj = {
    streetAddress: addr.splice(1, addr.length - 3)?.join(','),
    flatBuilding: addr[0],
    zipCode: '',
    latLng: {
      lat: '',
      lng: '',
    },
    city: '',
    state: '',
    region: '',
  };

  const results = await geocodeByAddress(address);
  const latLng = await getLatLng(results[0]);
  const addrComponent = results[0].address_components;
  addressObj.city = addrComponent.find((comp) => comp.types.indexOf('locality') >= 0)?.long_name;
  addressObj.zipCode = addrComponent.find(
    (comp) => comp.types.indexOf('postal_code') >= 0
  )?.long_name;
  addressObj.state = addrComponent.find(
    (comp) => comp.types.indexOf('administrative_area_level_1') >= 0
  )?.long_name;
  const region = cities.find((city) => toLower(city.name) === toLower(addressObj.city));
  return {
    ...addressObj,
    region,
    latLng,
  };
};

function toFloat(value, decimals) {
  return value ? Number(value?.toFixed(decimals || 2)) : 0;
}

function toNumber(value) {
  return value ? Math.abs(value) : 0;
}

function toRupees(amount, withSymbol, decimal) {
  return withSymbol
    ? paisa.formatWithSymbol((amount || 0) * 100, decimal)
    : paisa.format(Math.round((amount || 0) * 100), decimal);
}

function toPaisa(rupees) {
  return rupees * 100;
}

export function toQuantity(qty) {
  return numberWithCommas(Math.round(qty || 0));
}

function percentage(percent, total) {
  return Math.round(Number((percent / 100) * total) || 0);
}

export function getPercentageChange(today = 0, yesterday = 0) {
  const diff = today - yesterday;
  const isIncreased = !Number.isFinite(diff) || diff >= 0;
  return {
    percentageChange: yesterday ? (Math.abs(diff) / yesterday) * 100 || 0 : 0,
    isIncreased,
  };
}

const getArialDistance = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const rad = (x) => (x * Math.PI) / 180;
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  const dInKm = d / 1000;
  return dInKm + percentage(18, dInKm); // returns the distance in meter
};

function getExtraResponse(response) {
  const totalCount = Number(response?.headers['x-total-count']);
  const data = response?.data;
  return {
    data,
    totalCount,
    response,
  };
}
const hasStatus = (value, statuses) => statuses?.indexOf(value) >= 0;

const toArray = (obj) => {
  return Object.keys(obj).map((ele) => {
    return obj[ele];
  });
};

const sortDateAscending = (inputArray) => {
  return inputArray.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
};

const newDate = new Date();
const today = toServerDate(moment(newDate).format('YYYY-MM-DD'));
const lastWeekDate = toServerDate(moment(newDate).subtract(6, 'day').format('YYYY-MM-DD'));
const firstDayOfMonth = toServerDate(
  moment(new Date(newDate.getFullYear(), newDate.getMonth(), 1)).format('YYYY-MM-DD')
);
const firstDayOfYear = toServerDate(
  moment(new Date(newDate.getFullYear(), 0, 1)).format('YYYY-MM-DD')
);
const lastYearDate = toServerDate(moment(newDate).subtract(1, 'year').format('YYYY-MM-DD'));
const startOfWeek = moment(newDate).startOf('isoweek').toDate();

/**
 * return array of dates in @format given inclusive
 * of @startDate and @endDate
 * @returns String of date in @format
 */
export const getDatesBetween = (startDate, endDate, format = 'DD/MM/YYYY') => {
  const dates = [];
  const currDate = moment(startDate).subtract(1, 'day').startOf('day');
  const lastDate = moment(endDate).startOf('day');
  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.format(format));
  }
  return dates;
};
export const getWeekRange = (date) => {
  const dateFormat = 'DD/MM/YYYY';
  const startOfWeek1 = moment(date).startOf('isoWeek');
  const endOfWeek = moment(date).endOf('isoWeek');
  const month = moment(date).month();
  const startDate =
    startOfWeek1.month() !== month
      ? moment(date).startOf('month').format(dateFormat)
      : startOfWeek1.format(dateFormat);
  const endDate =
    endOfWeek.month() !== month
      ? moment(date).endOf('month').format(dateFormat)
      : endOfWeek.format(dateFormat);
  return { startDate, endDate };
};

/**
 * return array of months in @format given inclusive
 * of @startDate and @endDate
 * @returns String of date in @format
 */
export const getMonthsBetween = (startDate, endDate, format = 'YYYY-MM-01') => {
  const start = moment(startDate);
  const end = moment(endDate);
  const result = [];
  while (start.isBefore(end)) {
    result.push(start.format(format));
    start.add(1, 'month');
  }
  return result;
};

/**
 * @returns data with all missing dates {date: '', data}
 */
export const fillBlankDates = (from, to, data, dateKey = 'date', format) => {
  const allDates = getDatesBetween(from, to, format);
  const _data = keyBy(data, dateKey);
  return allDates?.map((date) => ({
    date,
    // weight: Math.floor(Math.random() * 6) + 1,
    ...(_data[date] ? _data[date] : {}),
  }));
};

export const formatAmount = (value) => {
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = `${(val / 10000000).toFixed(2)}Cr`;
  } else if (val >= 100000) {
    val = `${(val / 100000).toFixed(2)}L`;
  } else if (val > 1000) {
    val = `${(val / 1000).toFixed(2)}K`;
  }
  return val;
};

export const formatQuantity = (value) => {
  let val = Math.abs(value);
  if (val > 1000) {
    val = `${(val / 1000).toFixed(2)}MT`;
  } else {
    val = `${val}Kg`;
  }
  return val;
};

const amPMFrom24 = (timeString) => {
  return moment(timeString, ['HH:mm']).format('h:mm A');
};

const dateFromString = (dateString) => {
  return moment(new Date(dateString)).format('YYYY-MM-DD');
};

const monthFromDateObj = (dateObj) => {
  return moment(dateObj, 'YYYY-MM-DD').format('M');
};

const dateInWords = (dateString) => {
  return moment(new Date(dateString), 'YYYY-MM-DD').format('dddd, MMMM Do YYYY');
};

const dateInDayMonth = (dateString) => {
  return moment(new Date(dateString), 'YYYY-MM-DD').format('DD MMM');
};

const dateInDayDateInWords = (dateString) => {
  return moment(new Date(dateString), 'YYYY-MM-DD').format('ddd, DD MMMM YYYY');
};

export function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

const getDatesList = (startDate, stopDate) => {
  const dateArray = [];
  let currentDate = moment(startDate);
  const endDate = moment(stopDate);
  while (currentDate <= endDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

export const imageToBase64String = (templateCanvas) => {
  //  Initially we need to create canvas and set height and width for it.
  const canvas = document.createElement('canvas');
  canvas.height = templateCanvas.naturalHeight;
  canvas.width = templateCanvas.naturalWidth;
  canvas.getContext('2d').drawImage(templateCanvas, 0, 0);
  // To convert image to base64string
  const base64String = canvas.toDataURL('image/png');
  return base64String;
};

export {
  groupBy,
  toLocalDate,
  toLocalDateTime,
  toServerDate,
  toCapitalize,
  numberWithCommas,
  deepKeyBy,
  extractError,
  hasError,
  parseStringAddress,
  toRupees,
  toPaisa,
  getDateTimeAge,
  percentage,
  hasStatus,
  getExtraResponse,
  toNumber,
  toFloat,
  getDateDiff,
  isExist,
  getArialDistance,
  toArray,
  sortDateAscending,
  lastWeekDate,
  today,
  firstDayOfMonth,
  firstDayOfYear,
  lastYearDate,
  startOfWeek,
  amPMFrom24,
  dateFromString,
  monthFromDateObj,
  dateInWords,
  dateInDayMonth,
  dateInDayDateInWords,
  getDatesList,
};
