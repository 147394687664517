import { isEmpty, keyBy } from 'lodash';
import { useSelector } from 'react-redux';

export default function useAppConfig() {
  const { theme, locations, containerType } = useSelector(({ config, location }) => ({
    ...config,
    ...location,
  }));

  const rvmLocations = locations.filter((loc) => {
    return loc?.type === 'RVM';
  });

  return {
    locations,
    rvmLocations,
    hasRvmLocation: !isEmpty(rvmLocations),
    locationsMap: keyBy(locations, 'id'),
    theme,
    containerType,
    containerTypeMap: keyBy(containerType, 'id'),
  };
}
