/* eslint-disable max-len */
import React from 'react';

const TransactionsIcon = () => {
  return (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4416 25.3111C22.7647 25.3111 23.8412 24.2343 23.8412 22.9106V21.3354H20.5488C18.0477 21.3354 16.013 19.3007 16.013 16.7996C16.013 14.2986 18.0477 12.2638 20.5488 12.2638H23.8412V11.3402C23.8412 10.0171 22.7647 8.94064 21.4416 8.94064H3.9996C2.67646 8.94064 1.6 10.0171 1.6 11.3402V22.9106C1.6 24.2343 2.67646 25.3111 3.9996 25.3111H21.4416ZM21.4416 26.9111H3.9996C1.79421 26.9111 0 25.1165 0 22.9106V11.3402C0 9.13485 1.79421 7.34064 3.9996 7.34064H21.4416C23.647 7.34064 25.4412 9.13485 25.4412 11.3402V13.8728C25.39 13.8672 25.3356 13.8638 25.2783 13.8638H20.5488C18.93 13.8638 17.613 15.1808 17.613 16.7996C17.613 18.4184 18.93 19.7354 20.5488 19.7354H25.2783C25.3325 19.7354 25.3873 19.7324 25.4412 19.7265V22.9106C25.4412 25.1165 23.647 26.9111 21.4416 26.9111Z"
        fill="#8D97AD"
      />
      <path
        d="M4.43066 7.7937L15.7946 1.21899C16.166 1.01257 16.6002 0.949116 17.015 1.04126C17.4298 1.1334 17.7965 1.37474 18.0457 1.71899C18.8677 2.96999 21.5116 7.7937 21.5116 7.7937"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.75488 7.79355L18.3319 2.29355" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M12.9717 7.9736L19.4037 4.11569" strokeWidth="1.6" strokeLinecap="round" />
      <path
        d="M25.0967 13.3055C25.0967 13.3055 26.4746 13.1177 26.4336 14.2747C26.3926 15.4317 26.4336 19.1067 26.4336 19.1067C26.4336 19.1067 26.5437 20.3067 25.0967 20.3357"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8753 17.9306C21.3199 17.9306 21.6803 17.5702 21.6803 17.1256C21.6803 16.681 21.3199 16.3206 20.8753 16.3206C20.4307 16.3206 20.0703 16.681 20.0703 17.1256C20.0703 17.5702 20.4307 17.9306 20.8753 17.9306Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TransactionsIcon;
