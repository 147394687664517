/* eslint-disable max-len */
import React from 'react';

const DistributionPointIcon = () => {
  return (
    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6291 5.47369H4.78579C4.41123 5.47369 4.10352 5.77622 4.10352 6.15597V11.6296C4.10352 12.0039 4.41124 12.3116 4.78579 12.3116H11.6237C12.0034 12.3116 12.3111 12.0039 12.3111 11.6294V6.15597C12.3111 5.77622 12.0034 5.47369 11.6289 5.47369H11.6291ZM10.9416 10.9419H5.47345V6.838H10.9471L10.9468 10.9419H10.9416Z"
        fill="#8D97AD"
      />
      <path
        d="M30.0966 12.3114H17.6572C17.375 11.5163 16.6209 10.9417 15.7337 10.9417C15.4926 10.9417 15.2668 10.9929 15.0514 11.0699L15.0512 4.10387H15.7335C16.108 4.10387 16.4157 3.79615 16.4157 3.4216V0.687475C16.4157 0.307723 16.108 0 15.7335 0H0.682276C0.307717 0 0 0.307723 0 0.682276V3.4164C0 3.79615 0.307723 4.10387 0.682276 4.10387H1.36455V23.941C1.36975 24.3155 1.67227 24.6232 2.05203 24.6232H17.0978C17.4773 24.6232 17.78 24.3155 17.78 23.941V17.7851H30.0918C31.6 17.7851 32.826 16.5591 32.826 15.0509C32.826 13.5426 31.6052 12.3114 30.0968 12.3114L30.0966 12.3114ZM28.0243 13.9632L29.1324 16.4153H27.4702C27.4702 16.323 27.4549 16.2254 27.4138 16.1331L26.3057 13.6809H27.9678C27.9678 13.7735 27.9832 13.8708 28.0243 13.9632ZM21.2221 16.1331L20.114 13.6809H21.7761C21.7761 13.7733 21.7915 13.8708 21.8326 13.9632L22.9456 16.4153H21.2835C21.2785 16.323 21.2632 16.2254 21.2221 16.1331ZM24.3154 16.1331L23.2073 13.6809H24.8694C24.8694 13.7733 24.8848 13.8708 24.9259 13.9632L26.0389 16.4153H24.3768C24.3768 16.323 24.3614 16.2254 24.3154 16.1331ZM1.3695 1.36968H15.0507V2.73944L1.3695 2.7392V1.36968ZM2.73406 4.10381H13.6759V23.2535H2.73406V4.10381ZM16.4152 23.2535H15.0455V12.9936C15.0455 12.6141 15.3532 12.3114 15.7278 12.3114C16.1075 12.3114 16.4152 12.6191 16.4152 12.9936V23.2535ZM17.785 13.6817H18.6827C18.6827 13.774 18.698 13.8715 18.7391 13.9639L19.847 16.4158H17.7796L17.7798 13.6817H17.785ZM30.5533 16.3286C30.5431 16.262 30.5379 16.1952 30.5072 16.1338L29.3991 13.6816H30.0967C30.8508 13.6816 31.4665 14.2973 31.4665 15.0514C31.4611 15.6411 31.0815 16.1439 30.5533 16.3286L30.5533 16.3286Z"
        fill="#8D97AD"
      />
      <path
        d="M12.3118 14.3639C12.3118 14.7408 12.0065 15.0462 11.6295 15.0462C11.2529 15.0462 10.9473 14.7408 10.9473 14.3639C10.9473 13.9872 11.2529 13.6819 11.6295 13.6819C12.0065 13.6819 12.3118 13.9872 12.3118 14.3639Z"
        fill="#8D97AD"
      />
    </svg>
  );
};

export default DistributionPointIcon;
