import React from 'react';

import CollectionCenterIcon from '../../assets/svg/CollectionCenterIcon';
import CustomersIcon from '../../assets/svg/CustomersIcon';
import DashboardIcon from '../../assets/svg/DashboardIcon';
import DistributionPointIcon from '../../assets/svg/DistributionPointIcon';
import ManageQrIcon from '../../assets/svg/ManageQrIcon';
import MyAccountIcon from '../../assets/svg/MyAccountIcon';
import QRHistoryIcon from '../../assets/svg/QRHistoryIcon';
import TransactionsIcon from '../../assets/svg/TransactionsIcon';
import UsersIcon from '../../assets/svg/UsersIcon';

const nav = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    url: '/',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Tenants',
    icon: <DashboardIcon />,
    url: '/',
    authorities: ['ROLE_PLATFORM_ADMIN'],
  },
  {
    title: 'Customers',
    icon: <CustomersIcon />,
    url: '/customers',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Distribution Points',
    icon: <DistributionPointIcon />,
    url: '/distribution-points',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Collection Centers',
    icon: <CollectionCenterIcon />,
    url: '/collection-points',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Manage QR Codes',
    icon: <ManageQrIcon />,
    url: '/manage-qr-codes',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Transactions',
    icon: <TransactionsIcon />,
    url: '/transactions',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Users',
    icon: <UsersIcon />,
    url: '/users',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Reports',
    icon: <MyAccountIcon />,
    url: '/reports',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'Lot and QR History',
    icon: <QRHistoryIcon />,
    url: '/qr-history',
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER', 'ROLE_SUB_TENANT_ADMIN'],
  },
  {
    title: 'RVM',
    icon: <DashboardIcon />,
    url: '/RVM',
    authorities: ['ROLE_ADMIN'],
  },
];

export default nav;
